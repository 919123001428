import React from "react"

/* Import Global Component(s) */
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
// import PageHeader from "~components/page/components/pageHeader/pageHeader"

import ProfileHeader from './profileHeader'
import PageArticle from "~components/page/components/pageArticle/pageArticle"

/* Import Local Style(s) */
import "./profile-page.scss"

const ProfilePage = ({ title, header, location, children }) => {
  return (
    <Page className="page__profile-page" title={title} location={location}>
      <ProfileHeader title={header} location={location} />
      <PageArticle>{children}</PageArticle>
      <Footer />
    </Page>
  )
}

export default ProfilePage

import React, { useEffect, useState, useRef } from "react"
import { navigate } from "gatsby"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"


/* Import Page Component(s) */
import ProfilePage from "~components/pages/profile/profilePage"
// import VipPage from "~components/pages/vip/vipPage/vipPage"
// import RegisterForm from "~components/pages/vip/registerForm/registerForm"
// import LoginForm from "~components/pages/vip/loginForm/loginForm"

import AccountApplyForm from '~components/pages/profile/accountApplyForm'


/* Import Local Style(s) */
import "./apply.scss"

const Apply = ({ location }) => {

  return (
    <ProfilePage title="Apply for VIP status" header={"Apply for VIP status"} location={location}>
      {/*<RegisterForm setEmail={setEmail} />*/}
      <AccountApplyForm />
      <div className="links">
        <Link to="/vip">Login</Link>
      </div>
    </ProfilePage>
  )
}

export default Apply

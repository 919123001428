import React from "react"
import { parseISO } from "date-fns"
import { formatToTimeZone } from "date-fns-timezone"

/* Import Global Component(s) */
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Asset(s) */
import CrossSvgSrc from "~assets/images/close.svg"

/* Import Local Asset(s) */

const ProfileHeader = ({ location, title }) => {
  const prevUrlPath = location.state?.prevUrlPath

  return (
    <header className="profile-header">
      <Link to={prevUrlPath ? prevUrlPath : "/"} className="close">
        <img src={CrossSvgSrc} alt="Cross" />
      </Link>
      <h1>{title}</h1>
    </header>
  )
}

export default ProfileHeader
